import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { INSTALLMENT } from "../../../../campaign-config"
import LeadGeneration from "../../LeadGeneration"

const InstallmentLeadGeneration = () => {
  const intl = useIntl()

  return (
    <LeadGeneration campaign={INSTALLMENT} className="whatsapp-bg-color btn-block">
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            defaultMessage:
              "<b>Sign Up now</b> and <b>test your first event for free</b>. You are few clicks away to sell more tickets more earlier.",
          }),
        }}
      />
    </LeadGeneration>
  )
}

export default InstallmentLeadGeneration
