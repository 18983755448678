import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Badge, Col, Container, Row } from "react-bootstrap"
import Image1 from "../../../assets/images/campaign-installment/undraw_apps_m7mh.svg"
import Image3 from "../../../assets/images/campaign-installment/undraw_Artificial_intelligence_oyxx.svg"
import Image2 from "../../../assets/images/campaign-installment/undraw_happy_news_hxmt.svg"
import "./scss/index.scss"

const Steps = () => {
  const intl = useIntl()

  return (
    <section className="py-8 steps bg-light">
      <Container>
        <Row className="mb-5 pb-5">
          <Col className="text-center">
            <h2>{intl.formatMessage({ defaultMessage: "How does it work?" })}</h2>
            <span className="lead">
              {intl.formatMessage({
                defaultMessage:
                  "An innovative approach to sell your tickets earlier. With installment payments activated you will still earn the same amount. However, it feels less for your guests, as they pay it monthly with smaller amounts.",
              })}
            </span>
          </Col>
        </Row>

        <Row className="bg-white">
          <Col xs={12} className="mt-n5 text-center">
            <Badge className="my-3 whatsapp-bg-color">
              <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "In short" })}</p>
            </Badge>
          </Col>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={Image1} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">
                  {intl.formatMessage({ defaultMessage: "Minimal Risk" })}
                </p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "Lower perceived ticket prices reduce the inhibition threshold of your guests. They buy their ticket earlier and pay it risk-free in monthly installments.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    'If a guest thinks otherwise, he / she can "cancel" the ticket for free up to a certain date. The full amount will be refunded (optional feature).',
                })}
              </p>
            </Col>
          </Row>

          {/* Text right */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 1 }} className="text-center py-3">
              <img src={Image2} alt="..." className="steps__step-svg" data-aos="fade-right" />
            </Col>
            <Col xs={{ span: 8, order: 2 }} data-aos="fade-left">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "No Markup" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage: "The guests pay the normal ticket price without surcharge.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "There is only a small installment fee, which can not be refunded.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={Image3} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">
                  {intl.formatMessage({ defaultMessage: "Automated Payment" })}
                </p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "Payment is fully automated, punctually on the first day of the new month.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({ defaultMessage: "No extra work needed from your side." })}
              </p>
            </Col>
          </Row>
        </Row>
      </Container>
    </section>
  )
}

export default Steps
