import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"

const LeadGeneration = ({ children, className, campaign }) => (
  <section className="pt-8">
    <Container>
      <Row>
        <Col className="justify-contents-center text-center">
          <h2 className="">{children}</h2>
          <form
            name="lead"
            method="post"
            action="/thanks"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="form-row px-lg-8">
              <div className="form-group col-md-5">
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                />
              </div>
              <div className="form-group col-md-5">
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
              </div>
              <div className="form-group col-md-2">
                <Button type="submit" className={className ? className : "btn-primary btn-block"}>
                  Send
                </Button>
              </div>
            </div>
            <input type="hidden" name="campaign" value={campaign} />
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="lead" />
          </form>
        </Col>
      </Row>
    </Container>
  </section>
)

export default LeadGeneration
