import { IntlContextConsumer } from "gatsby-plugin-intl"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import PreviewImageDE from "../../../assets/images/campaign-installment/preview-de.jpg"
import PreviewImageEN from "../../../assets/images/campaign-installment/preview-en.jpg"
import MacBookSVG from "../../../assets/images/devices/macbook.svg"

const Preview = () => {
  return (
    <section className="pt-8">
      <Container>
        <Row>
          <Col>
            {/* Macbook + iPhone */}
            <div className="device-combo device-combo-macbook-iphonex mb-6 mb-md-0">
              {/* Macbook */}
              <div className="device device-macbook">
                <IntlContextConsumer>
                  {({ language: currentLocale }) => (
                    <img
                      className="device-screen"
                      src={currentLocale === "de" ? PreviewImageDE : PreviewImageEN}
                      alt="..."
                    />
                  )}
                </IntlContextConsumer>
                <img src={MacBookSVG} className="img-fluid" alt="..." />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Preview
